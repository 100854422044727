import createFormData from './helpers'
import { setModal } from '../reducers/Home/actions'

const getOptions = (operation, data) => {
  let options = {
    method: operation,
    mode: 'cors',
    headers: {
      // Authorization: getOssToken()
      // 'Content-Type': '*/*'
    },
  };

  if (data) {
      let fileFormData = createFormData(data);
      return { ...options, body: fileFormData };
  }
  return options;
};

const common = (body, type) => {
  return fetch(`${process.env.GATSBY_XELLA_BACKEND}/api/${type}/send-inquiry`, body)
    .then(response => {
      if (response.status === 204) {
        setModal()
        return Promise.resolve(response);
      }
      if (response.status === 415) {
        return { ErrorCode: 'EC_Invalid_Format' }
      }
      return response.json();
    })
    .then(data => {
      if (data.hasOwnProperty('ErrorCode')) {
        // throw Error(errorCodeCheck(data.ErrorCode))
        throw Error('Some Error')
      }
      return Promise.resolve(data);
    })
    .catch(error => {
      return Promise.reject(error)
    });
};

export const formData = {
  common,
  getOptions
} 