import React, { useState, useRef } from "react";
import Layout from "../../components/Layout";
import { navigate } from "gatsby";
import Moment from "react-moment";
import { Row, Col, Button, Progress, Input, InputGroup } from "reactstrap";
import icon1 from "../../assets/images/request-step-1.png";
import icon2 from "../../assets/images/request-step-2.svg";
import icon3 from "../../assets/images/request-step-3.svg";
import icon4 from "../../assets/images/request-step-4.png";
import infoIcon from "../../assets/images/info-icon.svg";
import calendarIcon from "../../assets/images/calendar-icon.svg";
import plosak from "../../assets/images/plosak.svg";
import fronton from "../../assets/images/fronton.svg";
import razchupen from "../../assets/images/razchupen.svg";
import ednoet from "../../assets/images/ednoet.svg";
import dvuet from "../../assets/images/dvuet.svg";
import mnogoet from "../../assets/images/mnogoet.svg";
import paperclip from "../../assets/images/paperclip.svg";
import checkIcon from "../../assets/images/check-icon.svg";
import CustomizedCheckbox from "../../components/Checkbox/Checkbox";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { formData } from '../../utils/formData'
import { setModal } from '../../reducers/Home/actions'
import { nameRegex, validateEmail, phoneRegex, locationRegex } from '../../utils/util'
import { connect } from "react-redux";
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const BuildingRequest = ({ serviceType, details, setModal }) => {
  const [step, setStep] = useState(1);
  const [plan, setPlan] = useState(null);
  const [permission, setPermission] = useState(null);
  const [houseSize, setHouseSize] = useState(0);
  const [roofType, setRoofType] = useState("Не е посочено");
  const [basement, setBasement] = useState(false);
  const [attic, setAttic] = useState(false);
  const [buildingType, setBuildingType] = useState("Не е посочено");
  const [additionalOption, setAdditionalOption] = useState([]);
  const [projectUrl, setProjectUrl] = useState(null);
  const [message, setMessage] = useState("Не е посочено");
  const [files, setFiles] = useState(null);
  const [fileName, setFileName] = useState([]);
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [location, setLocation] = useState('')
  const [gdpr, setGDPR] = useState(false)
  const [terms, setTerms] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [validate, setValidate] = useState(false)
  const [validateStep1, setValidateStep1] = useState(false)
  const [validateStep2, setValidateStep2] = useState(false)
  const [validateStep3, setValidateStep3] = useState(false)

  const fileInput = useRef(null)

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  const fileInputHandler = () => {
    fileInput.current.click()
  }

  const checkOption = (name) => {
    let index = additionalOption.indexOf(name);
    let newArr = additionalOption.slice();

    if (index === -1) {
      newArr.push(name);
    } else {
      newArr.splice(index, 1);
    }

    setAdditionalOption(newArr);
  };

  const onFileChange = (e) => {
    let currentFile = Object.entries(e);
    let fileArr = [];

    currentFile.map(file => {
      fileArr.push(`${file[1].name}`)
    })
    setFileName(fileArr);

    let newFiles = files ? [...files] : []
    newFiles.push(e)
    setFiles(newFiles)
  }

  const progressBar = (icon, title, step, progress) => {
    return (
      <Row>
        <Col className="icon-col1">
          <div
            className={
              progress === 100 ? "icon-container success" : "icon-container"
            }
          >
            <img
              alt="icon"
              className={progress === 60 ? "icon filter" : "icon"}
              src={icon}
            />
          </div>
        </Col>
        <Col className="icon-col2">
          <h4>{title}</h4>
          <p className="text-gray">Стъпка {step}/5</p>
          <Progress
            color={progress === 100 ? "success" : ""}
            value={progress}
          />
        </Col>
      </Row>
    );
  };

  const validateFields = () => {
    if (name.length === 0 || !nameRegex.test(name) || !phoneRegex.test(phone) || phone.length === 0 ||
      !validateEmail(email) || email.length === 0 || location.length === 0 || location.length > 35
      || !gdpr || !terms || !marketing) {
      return true
    } else return false
  }

  const validateStep1Fields = () => {
    if (plan !== null) {
      return true
    } else return false
  }
  const validateStep1Inputs = () => {
    if (plan === null) {
      return 'Моля изберете опция!'
    }
  }

  const validateStep2Fields = () => {
    if (permission !== null) {
      return true
    } else return false
  }
  const validateStep2Inputs = () => {
    if (permission === null) {
      return 'Моля изберете опция!'
    }
  }

  const validateStep3Fields = () => {
    if (houseSize > 0) {
      return true
    } else return false
  }
  const validateStep3Inputs = () => {
    if (houseSize <= 0) {
      return 'Моля попълнете квадратурата!'
    }
  }

  const validateInputs = (name, phone, email, location) => {
    if (!nameRegex.test(name) || name.length > 35 || name.length === 0) {
      return 'Невалидно име! Допустими символи: латиница и кирилица.'
    }
    if (!phoneRegex.test(phone) || phone.length > 25 || phone.length === 0) {
      return 'Невалиден телефонен номер! Допустими символи: само цифри.'
    }
    if (!validateEmail(email) || email.length > 254 || email.length === 0) {
      return 'Невалиден имейл адрес! Допустими символи: латиница, цифри, и специални символи.'
    }
    if (location.length > 35 || location.length === 0) {
      return 'Невалидна локация! Допустими символи до 35'
    }
    if (!gdpr) {
      return 'Липсва съгласие за обработка на лични данни.'
    }
    if (!terms) {
      return 'Липсва съгласие за правилата за ползване на уебсайта.'
    }
    if (!marketing) {
      return 'Липсва съгласие за правилата за маркетинг.'
    }
    return ''
  }

  const switchStep = (data) => {
    switch (data) {
      case 1:
        return (
          <>
            <Col className="building-request-left-col" lg={8}>
              {progressBar(icon1, "Създайте заявка", 1, 20)}

              <div className="building-request-content">
                <h2>Имате ли план за къща?</h2>
                <p className="text-gray">
                  Ще зададем няколко въпроса, за да предоставим на
                  потенциалните изпълнители възможно най-много информация за
                  оценката на строителтвото.
                </p>
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setPlan(true)}
                onClick={() => setPlan(true)}
                className="d-flex justify-content-between align-content-center py-3 px-4 mx-2 switch-type-btn mb-4"
              >
                <p className="d-inline mb-0">Да</p>
                <Input
                  type="radio"
                  name="radio1"
                  checked={plan === true}
                />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setPlan(false)}
                onClick={() => setPlan(false)}
                className="d-flex justify-content-between align-content-center py-3 px-4 mx-2 switch-type-btn mb-4"
              >
                <p className="d-inline mb-0">Не</p>
                <Input
                  type="radio"
                  name="radio2"
                  checked={plan === false}
                />
              </div>

              {
                validateStep1 &&
                <div className='data-alert text-align-left mx-auto px-2 mt-5'>
                  {validateStep1Inputs()}
                </div>
              }

              <div className="building-request-btn-container">
                {
                  details &&
                  <Button
                    className="back-btn"
                    onClick={() => details && navigate(`/service-details?id=${details.id}&service=${serviceType}`)}
                  >
                    <KeyboardArrowLeftIcon /> обратно
                  </Button>

                }

                <Button
                  onClick={() => {
                    if (validateStep1Fields()) {
                      setValidateStep1(false);
                      setStep(step + 1);
                      navigate("/building-request");
                    }
                    setValidateStep1(true);
                  }}
                  className="btn btn-lg"
                >
                  НАПРЕД
                </Button>
              </div>
            </Col>
            <Col className="building-request-right-col" lg={4}>
              <div className="tracking-container">
                <div className="tracking-container-inner">
                  <h3>{details && details.title}</h3>
                  <p className="text-gray">
                    {details && details.districts.length > 0
                      ? `Предоставя услуги: област ${details.districts.join(', област ')}`
                      : `Предоставя услуги в цяла България.`
                    }
                  </p>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="date-icon-container">
                        <img
                          alt="calendar"
                          className="date-icon"
                          src={calendarIcon}
                        />
                      </div>
                    </Col>
                    <Col>
                      <h6>Дата на заявката</h6>
                      <Moment format="DD.MM.YYYY">
                        <p className="text-gray">{Date.now()}</p>
                      </Moment>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </>
        );
      case 2:
        return (
          <>
            <Col className="building-request-left-col" lg={8}>
              {progressBar(icon2, "Създайте заявка", 2, 40)}

              <div className="building-request-content">
                <h2>Имате ли вече разрешение за строеж?</h2>
                <p className="text-gray">
                  Тази информация ще позволи на изпълнителя да определи по
                  -точно очакваната дата на започване на строителството.
                </p>
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setPermission("Да")}
                onClick={() => setPermission("Да")}
                className="d-flex justify-content-between align-content-center py-3 px-4 mx-2 switch-type-btn mb-4"
              >
                <p className="d-inline mb-0">Да</p>
                <Input
                  type="radio"
                  name="Да"
                  checked={permission === "Да" ? true : false}
                  onChange={() => console.log()}
                />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setPermission("Очаквам")}
                onClick={() => setPermission("Очаквам")}
                className="d-flex justify-content-between align-content-center py-3 px-4 mx-2 switch-type-btn mb-4"
              >
                <p className="d-inline mb-0">Очаквам</p>
                <Input
                  type="radio"
                  name="Очаквам"
                  checked={permission === "Очаквам" ? true : false}
                  onChange={() => console.log()}
                />
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => setPermission("Не")}
                onClick={() => setPermission("Не")}
                className="d-flex justify-content-between align-content-center py-3 px-4 mx-2 switch-type-btn mb-4"
              >
                <p className="d-inline mb-0">Не</p>
                <Input
                  type="radio"
                  name="Не"
                  checked={permission === "Не" ? true : false}
                  onChange={() => console.log()}
                />
              </div>

              {
                validateStep2 &&
                <div className='data-alert text-align-left mx-auto px-2 mt-5'>
                  {validateStep2Inputs()}
                </div>
              }

              <div className="building-request-btn-container">
                <Button
                  onClick={() => {
                    setStep(step - 1);
                    navigate("/building-request");
                  }}
                  className="back-btn"
                >
                  <KeyboardArrowLeftIcon /> обратно
                </Button>
                <Button
                  onClick={() => {
                    if (validateStep2Fields()) {
                      setValidateStep2(false);
                      setStep(step + 1);
                      navigate("/building-request");
                    }
                    setValidateStep2(true);
                  }}
                  className="btn btn-lg"
                >
                  НАПРЕД
                </Button>
              </div>
            </Col>
            <Col className="building-request-right-col" lg={4}>
              <div className="tracking-container">
                <div className="tracking-container-inner">
                  <h3>{details && details.title}</h3>
                  <p className="text-gray">
                    {details && details.districts.length > 0
                      ? `Предоставя услуги: област ${details.districts.join(', област ')}`
                      : `Предоставя услуги в цяла България.`
                    }
                  </p>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="date-icon-container">
                        <img
                          alt="calendar"
                          className="date-icon"
                          src={calendarIcon}
                        />
                      </div>
                    </Col>
                    <Col>
                      <h6>Дата на заявката</h6>
                      <Moment format="DD.MM.YYYY">
                        <p>{Date.now()}</p>
                      </Moment>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>1</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>План за къща</h6>
                      <p className="text-gray">
                        {plan === true ? "Да" : "Не"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </>
        );
      case 3:
        return (
          <>
            <Col className="building-request-left-col" lg={8}>
              {progressBar(icon3, "Създайте заявка", 3, 60)}

              <div className="building-request-content">
                <h2>Опишете планираната си къща</h2>
                <p>Каква ще бъде общата използваема площ на къщата? *</p>
              </div>
              <div className="house-size-input">
                <Input
                  type="input"
                  name="houseSize"
                  value={houseSize}
                  onChange={(e) => setHouseSize(Number(e.target.value))}
                />{" "}
                <p>m<sup>2</sup></p>
              </div>

              <div className="request-cards">
                <hr />
                <p>Изберете типа покрив *</p>
                <Row>
                  <Col>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setRoofType("Плосък")}
                      onClick={() => setRoofType("Плосък")}
                      className={
                        roofType === "Плосък"
                          ? "request-card request-card__active"
                          : "request-card"
                      }
                    >
                      <img alt="roof" className="mt-2" src={plosak} />
                      <p>плосък</p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setRoofType("Двускатен")}
                      onClick={() => setRoofType("Двускатен")}
                      className={
                        roofType === "Двускатен"
                          ? "request-card request-card__active"
                          : "request-card"
                      }
                    >
                      <img alt="roof" src={fronton} />
                      <p>двускатен</p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setRoofType("Многоскатен")}
                      onClick={() => setRoofType("Многоскатен")}
                      className={
                        roofType === "Многоскатен"
                          ? "request-card request-card__active"
                          : "request-card"
                      }
                    >
                      <img alt="roof" src={razchupen} />
                      <p>многоскатен</p>
                    </div>
                  </Col>
                </Row>
                <hr />
                <p>Изберете типа постройка *</p>
                <Row>
                  <Col>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setBuildingType("Едноетажна")}
                      onClick={() => setBuildingType("Едноетажна")}
                      className={
                        buildingType === "Едноетажна"
                          ? "request-card request-card__active"
                          : "request-card"
                      }
                    >
                      <img alt="building" src={ednoet} />
                      <p>едноетажна</p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setBuildingType("Двуетажна")}
                      onClick={() => setBuildingType("Двуетажна")}
                      className={
                        buildingType === "Двуетажна"
                          ? "request-card request-card__active"
                          : "request-card"
                      }
                    >
                      <img alt="building" src={dvuet} />
                      <p>двуетажна</p>
                    </div>
                  </Col>
                  <Col>
                    <div
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => setBuildingType("Многоетажна")}
                      onClick={() => setBuildingType("Многоетажна")}
                      className={
                        buildingType === "Многоетажна"
                          ? "request-card request-card__active"
                          : "request-card"
                      }
                    >
                      <img
                        alt="building"
                        className="many-floors-icon"
                        src={mnogoet}
                      />
                      <p className="mb-3">многоетажна</p>
                    </div>
                  </Col>
                </Row>
                <div className="request-checkbox-container">
                  <p>Допълнителни опции</p>
                  <Row>
                    <Col>
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => setBasement(!basement)}
                        onClick={() => {
                          checkOption("Мазе")
                          setBasement(!basement)
                        }}
                        className="request-checkbox"
                      >
                        <CustomizedCheckbox
                          checked={additionalOption.indexOf('Мазе') >= 0}
                        />
                        <p>Мазе</p>
                      </div>
                    </Col>
                    <Col>
                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => setAttic(!attic)}
                        onClick={() => {
                          setAttic(!attic)
                          checkOption("Таванско помещение")
                        }}
                        className="request-checkbox"
                      >
                        <CustomizedCheckbox
                          checked={additionalOption.indexOf('Таванско помещение') >= 0}
                        />
                        <p>Таванско помещение</p>

                      </div>
                    </Col>
                  </Row>
                  {
                    validateStep3 &&
                    <div className='data-alert text-align-left mx-auto px-2 mt-5'>
                      {validateStep3Inputs()}
                    </div>
                  }
                </div>
              </div>
              <div className="building-request-btn-container">
                <Button
                  onClick={() => {
                    setStep(step - 1);
                    navigate("/building-request");
                  }}
                  className="back-btn"
                >
                  <KeyboardArrowLeftIcon /> обратно
                </Button>
                <Button
                  onClick={() => {
                    if (validateStep3Fields()) {
                      setValidateStep3(false);
                      setStep(step + 1);
                      navigate("/building-request");
                    }
                    setValidateStep3(true);
                  }}
                  className="btn btn-lg"
                >
                  НАПРЕД
                </Button>
              </div>
            </Col>
            <Col className="building-request-right-col" lg={4}>
              <div className="tracking-container">
                <div className="tracking-container-inner">
                  <h3>{details && details.title}</h3>
                  <p className="text-gray">
                    {details && details.districts.length > 0
                      ? `Предоставя услуги: област ${details.districts.join(', област ')}`
                      : `Предоставя услуги в цяла България.`
                    }
                  </p>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="date-icon-container">
                        <img
                          alt="calendar"
                          className="date-icon"
                          src={calendarIcon}
                        />
                      </div>
                    </Col>
                    <Col>
                      <h6>Дата на заявката</h6>
                      <Moment format="DD.MM.YYYY">
                        <p className="text-gray">{Date.now()}</p>
                      </Moment>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>1</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>План за къща</h6>
                      <p className="text-gray">
                        {plan === true ? "Да" : "Не"}
                      </p>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>2</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>Разрешение за строеж</h6>
                      <p className="text-gray">
                        {permission}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </>
        );
      case 4:
        return (
          <>
            <Col className="building-request-left-col" lg={8}>
              {progressBar(icon4, "Създайте заявка", 4, 80)}

              <div className="building-request-content">
                <h2>Добавете проект за вашия дом</h2>
                <p className="text-gray">
                  Това е последната стъпка и вашето запитване ще бъде готово за
                  изпращане!
                </p>
              </div>
              <div className="py-3 request-info">
                <img alt="info" src={infoIcon} />
                <span>
                  Добавянето на проект ще позволи на изпълнителя да опознае по
                  -добре поръчката, което от своя страна значително ще увеличи
                  шансовете ви да получите първоначална оферта по-бързо.
                </span>
              </div>
              <div className="mt-5">
                <p className="mb-2">Добавете файлове с проекта</p>
                <div
                  role='none'
                  onClick={() => fileInputHandler()}
                  className="py-4 px-5 px-md-0 request-file-upload"
                  style={{ cursor: "pointer" }}>
                  <div className="input-file-container w-100-i justify-content-center">
                    <div className="input-file-content">
                      <img alt="paperclip" src={paperclip} />
                      <div className='flex-direction-column'>
                        <label className="text-gray cursor-pointer">
                          Добавяне на файлове (максимум 10 MB)
                        </label>
                        <p className="m-0">
                          {fileName.length > 0 ? fileName.join(', ') : ""}
                        </p>
                      </div>
                    </div>
                    <input
                      id="files"
                      ref={fileInput}
                      className='cursor-pointer'
                      multiple
                      style={{ visibility: "hidden", width: 0 }}
                      type="file"
                      onChange={e => {
                        onFileChange(e.target.files)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="url-input">
                <p>
                  или поставете връзка за сваляне на проекта, ако е по-голям
                  от 10 MB
                </p>
                <Input
                  type="text"
                  name="projectUrl"
                  placeholder="http://"
                  onChange={(e) => setProjectUrl(e.target.value)}
                />
                <hr className="mt-5 mb-5" />
              </div>
              <div className="message-input">
                <p>Съобщение за изпълнителя</p>
                <Input
                  name="messageForBuilder"
                  className='py-2'
                  type="textarea"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="building-request-btn-container">
                <Button
                  onClick={() => {
                    setStep(step - 1);
                    navigate("/building-request");
                  }}
                  className="back-btn"
                >
                  <KeyboardArrowLeftIcon /> обратно
                </Button>
                <Button
                  onClick={() => {
                    setStep(step + 1);
                    navigate("/building-request");
                  }}
                  className="btn btn-lg"
                >
                  НАПРЕД
                </Button>
              </div>
            </Col>
            <Col className="building-request-right-col" lg={4}>
              <div className="tracking-container">
                <div className="tracking-container-inner">
                  <h3>{details && details.title}</h3>
                  <p className="text-gray">
                    {details && details.districts.length > 0
                      ? `Предоставя услуги: област ${details.districts.join(', област ')}`
                      : `Предоставя услуги в цяла България.`
                    }
                  </p>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="date-icon-container">
                        <img
                          alt="calendar"
                          className="date-icon"
                          src={calendarIcon}
                        />
                      </div>
                    </Col>
                    <Col>
                      <h6>Дата на заявката</h6>
                      <Moment format="DD.MM.YYYY">
                        <p className="text-gray">{Date.now()}</p>
                      </Moment>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>1</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>План за къща</h6>
                      <p className="text-gray">
                        {plan === true ? "Да" : "Не"}
                      </p>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>2</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>Разрешение за строеж</h6>
                      <p className="text-gray">
                        {permission}
                      </p>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>3</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>Квадратура</h6>
                      <p className="text-gray">{houseSize} m<sup>2</sup></p>
                      <h6 className="mt-4">Покрив</h6>
                      <p className="text-gray">{roofType}</p>
                      <h6 className="mt-4">Постройка</h6>
                      <p className="text-gray">{buildingType}</p>
                      <h6 className="mt-4">Допълнителни опции</h6>
                      <p className="text-gray">{additionalOption.join(", ")}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </>
        );
      case 5:
        return (
          <>
            <Col className="building-request-left-col" lg={8}>
              {progressBar(checkIcon, "Готово!", 5, 100)}

              <div className="building-request-content">
                <h2>Свържете се с изпълнителя</h2>
                <p className="text-gray">
                  Попълнете данните за контакт по-долу, за да може изпълнителят
                  да се свърже с вас възможно най-скоро.
                </p>
              </div>

              <div className="success-input-fields">
                <div className="modal-input-fields pt-2 pb-1">
                  <InputGroup className="input-group-custom mb-2 ">
                    <Input
                      maxLength={35}
                      type='text'
                      placeholder="Име и фамилия"
                      className="input-field py-3 px-3 border-radius box-shadow"
                      onChange={e => setName(e.target.value)}
                    />
                    <Input
                      maxLength={25}
                      type='number'
                      pattern="[0-9]+"
                      placeholder="Телефон"
                      className="input-field py-3 px-3 border-radius box-shadow"
                      onChange={e => setPhone(e.target.value)}
                    />
                  </InputGroup>

                  <InputGroup className="input-group-custom pt-2 pb-1">
                    <Input
                      type='email'
                      maxLength={254}
                      placeholder="Имейл"
                      className="input-field py-3 px-3 border-radius box-shadow"
                      onChange={e => setEmail(e.target.value)}
                    />
                    <Input
                      type='text'
                      maxLength={25}
                      placeholder="Локация"
                      className="input-field py-3 px-3 border-radius box-shadow"
                      onChange={e => setLocation(e.target.value)}
                    />
                  </InputGroup>
                </div>
                <Row className="privacy-checkbox-row">
                  <Col className="request-checkbox-col">
                    <div className="">
                      <div className='d-flex'>
                        <CustomizedCheckbox
                          onChange={() => setGDPR(!gdpr)}
                        />
                        <label className="py-2 checkbox-label d-flex align-items-center" htmlFor="privacy">
                          <span>
                            Съгласен съм личните ми данни да бъдат обработвани от "КСЕЛА България" ЕООД в съответствие с <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/GDPR`, "_blank")}>Политиката за защита на личните данни на КСЕЛА България</a>.
                          </span>
                        </label>
                      </div>
                      <div>
                        <CustomizedCheckbox
                          onChange={() => setTerms(!terms)}
                        />
                        <label className="py-2 checkbox-label" htmlFor="privacy">
                          <span>
                            Съгласен съм и приемам
                            <a href='#' className='blue-link' onClick={() => window.open(`https://building-companion.bg/terms-and-conditions?terms-and-conditions=1`, "_blank")}> правилата за ползване на уебсайта</a>.
                          </span>
                        </label>
                      </div>
                      <div>
                        <CustomizedCheckbox
                          onChange={() => setMarketing(!marketing)}
                        />
                        <label className="checkbox-label" htmlFor="privacy">
                          Съгласен съм личните ми данни да бъдат използвани за маркетингови цели.
                        </label>
                      </div>
                      <div>
                        {
                          validateFields() && validate &&
                          <div className='data-alert text-align-left mx-auto px-2 mt-3'>
                            {validateInputs(name, phone, email, location)}
                          </div>
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="building-request-btn-container">
                <Row>
                </Row>
                <Button
                  onClick={() => {
                    setStep(step - 1);
                    navigate("/building-request");
                  }}
                  className="back-btn"
                >
                  <KeyboardArrowLeftIcon /> обратно
                </Button>
                <Button
                  onClick={() => {
                    if (validateFields()) {
                      setValidate(true)
                      return
                    }
                    setStep(step + 1);
                    navigate(`/service-details?id=${details && details.id}`);
                    formData.common(
                      formData.getOptions('POST', {
                        builder_id: Number(details.id),
                        house_plan: plan,
                        building_permission: permission,
                        email,
                        area: houseSize,
                        roof_type: roofType,
                        building_type: buildingType,
                        has_basement: basement,
                        has_attic: attic,
                        files: files,
                        project_url: projectUrl,
                        full_name: name,
                        phone_number: phone,
                        location,
                        description: message,
                        gdpr_consent: gdpr,
                        terms_consent: terms,
                        marketing_consent: marketing
                      }), 'builders')
                      .then(res => {
                        setModal()
                      })
                    setGDPR(false)
                    setTerms(false)
                    setMarketing(false)
                  }}
                  className="btn btn-lg"
                >
                  ИЗПРАТЕТЕ ВАШЕТО ЗАПИТВАНЕ
                </Button>
              </div>
            </Col>
            <Col className="building-request-right-col success" lg={4}>
              <div className="tracking-container">
                <div className="tracking-container-inner">
                  <h3>{details && details.title}</h3>
                  <p className="text-gray">
                    {details && details.districts.length > 0
                      ? `Предоставя услуги: област ${details.districts.join(', област ')}`
                      : `Предоставя услуги в цяла България.`
                    }
                  </p>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="date-icon-container">
                        <img
                          alt="calendar"
                          className="date-icon"
                          src={calendarIcon}
                        />
                      </div>
                    </Col>
                    <Col>
                      <h6>Дата на заявката</h6>
                      <Moment format="DD.MM.YYYY">
                        <p className="text-gray">{Date.now()}</p>
                      </Moment>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>1</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>План за къща</h6>
                      <p className="text-gray">
                        {plan === true ? "Да" : "Не"}
                      </p>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>2</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>Разрешение за строеж</h6>
                      <p className="text-gray">
                        {permission}
                      </p>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>3</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>Квадратура</h6>
                      <p className="text-gray">{houseSize} m<sup>2</sup></p>
                      <h6 className="mt-4">Покрив</h6>
                      <p className="text-gray">{roofType}</p>
                      <h6 className="mt-4">Постройка</h6>
                      <p className="text-gray">{buildingType}</p>
                      <h6 className="mt-4">Допълнителни опции</h6>
                      <p className="text-gray">{additionalOption.join(", ")}</p>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div className="tracking-container-inner">
                  <Row className="tracking-row">
                    <Col className="date-icon-col">
                      <div className="number-icon-container">
                        <h6>4</h6>
                      </div>
                    </Col>
                    <Col>
                      <h6>Добавен файл на проекта</h6>
                      <p className="text-gray">
                        {
                          fileName.length > 0
                            ? `${fileName.length > 1
                              ? `${fileName.length} файла`
                              : `${fileName.length} файл`}`
                            : 'Не'}</p>
                      <h6 className="mt-4">Връзка към проекта</h6>
                      {projectUrl ? <a href={projectUrl}>{projectUrl}</a> : <a href="#">Не е посочено</a>}
                      <h6 className="mt-4">Съобщение за изпълнителя</h6>
                      <p className="text-gray">{message}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </>
        );
      default:
        break;
    }
  };

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="building-request-wrapper">
        <div className="building-request-container">
          <Row className="building-request-row">{switchStep(step)}</Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    details: state.services.details,
    serviceType: state.home.serviceType
  };
};

const mapDispatchToProps = {
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingRequest);
